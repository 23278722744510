.custom-calendar {
    width: 100%;
    max-width: 550px; 

    border-radius: 8px;
    padding: 15px;

}

.custom-calendar .react-datepicker__header {
  
 
    text-align: center;
    font-weight: bold;
    font-size: 1em; 
    padding: 10px;
    
    border-radius: 8px; 
    margin-bottom: 15px;
}

.custom-calendar .react-datepicker__current-month {
    font-size: 1.4em; 
    font-weight: bold;
    color: #333;
}

.custom-calendar .react-datepicker__navigation--previous,
.custom-calendar .react-datepicker__navigation--next {
    top: 15px;
    width: 30px;
    height: 30px;
}

.custom-calendar .react-datepicker__navigation-icon::before {
    border-color: #333;
    border-width: 2px;
}


.custom-calendar .react-datepicker__day-names .react-datepicker__day-name,
.custom-calendar .react-datepicker__day {
    width: 40px; 
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 2px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-size: 1em; 
    font-weight: bold; 
    color: #333;
}

.custom-calendar .react-datepicker__day--selected {
    background-color: green; 
    color: red;
    border-radius: 4px;
}

.custom-calendar .react-datepicker__day--highlighted {
    background-color: rgb(40, 155, 201); 
    color: #ffffff;
    border-radius: 4px;
    
}

.custom-calendar .react-datepicker__day--weekend {
    color: red; /* रविवार का लाल रंग */
}

.custom-calendar .react-datepicker__day--outside-month {
    color: #d1d5db; /* बाहर के महीनों के दिन */
}
