.container {
    max-width: 440px;
    background: #F8F9FD;
    border-radius: 40px;
    padding: 25px 35px;
    border: 5px solid #fff;
    box-shadow: rgba(133, 189, 215, 0.87) 0px 30px 30px -20px;
    margin: 200px auto;
    
  }
  .logo {
    display: block;
    margin: 0 auto 20px; /* Center the logo and add margin below it */
    max-width: 150px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
  }
  .heading {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    color: rgb(16, 137, 211);
  }
  
  .form {
    margin-top: 20px;
  }
  
  .input {
    width: 100%;
    background: white;
    border: none;
    padding: 15px 20px;
    border-radius: 20px;
    margin-top: 15px;
    box-shadow: #cff0ff 0px 10px 10px -5px;
  }
  
  .input:focus {
    outline: none;
    border: 2px solid #12B1D1;
  }
  
  .forgot-password {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .forgot-password a {
    font-size: 11px;
    color: #0099ff;
    text-decoration: none;
  }
  
  .login-button {
    display: block;
    width: 100%;
    font-weight: bold;
    background: linear-gradient(45deg, rgb(16, 137, 211) 0%, rgb(18, 177, 209) 100%);
    color: white;
    padding-block: 15px;
    margin: 20px auto;
    border-radius: 20px;
    border: none;
    transition: all 0.2s ease-in-out;
  }
  
  .login-button:hover {
    transform: scale(1.03);
  }
  
 
  

  
  .eye-icon {
    position: absolute;
    right: 20px;
    top: 60%;
    transform: translateY(-50%);
    cursor: pointer;
    color: gray;
  }
  
  .eye-icon.visible {
    color: blue; /* Change color when visible */
  }
  