@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';

/* Default Scrollbar Width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* Horizontal scrollbar ki width bhi set kar di */
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: #e0e0e0; /* Light gray background */
  border-radius: 10px;
}

/* Scrollbar Thumb */
::-webkit-scrollbar-thumb {
  background: #a0a0a0; /* Gray color for thumb */
  border-radius: 10px;
  border: 2px solid #e0e0e0; /* Border around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a; /* Darker gray on hover */
}

/* Remove Scrollbar for .hide-scrollbar Class */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Custom Scrollbar for .scrollable-modal */
.scrollable-modal {
  max-height: 60vh;
  overflow-y: auto;
}

.scrollable-modal::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollable-modal::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 10px;
}

.scrollable-modal::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Custom Scrollbar for .bg-white Elements */
.bg-white::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.bg-white::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

.bg-white::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}

.bg-white::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}

/* Custom Scrollbar for Body */
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 10px;
  border: 2px solid #e0e0e0;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #7a7a7a;
}
