.cs24-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(3px);
  flex-direction: column;
}

.cs24-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cs24-loader-text {
  font-size: 24px;
  font-weight: bold;
  color: #1171bb;
  animation: fadeIn 1.5s infinite alternate;
}

.cs24-loader-subtext {
  font-size: 14px;
  color: gray;
  margin-bottom: 15px;
  animation: fadeIn 2s infinite alternate;
}

/* Apply SVG styles only within loader */
.cs24-loader-container svg {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.cs24-loader-container circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes fadeIn {
  0% { opacity: 0.3; }
  100% { opacity: 1; }
}

@keyframes rotate4 {
  100% { transform: rotate(360deg); }
}

@keyframes dash4 {
  0% { stroke-dasharray: 1, 200; stroke-dashoffset: 0; }
  50% { stroke-dasharray: 90, 200; stroke-dashoffset: -35px; }
  100% { stroke-dashoffset: -125px; }
}
